import { FC } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { useSignedInUserGuard } from "hooks/useSignedInUserGuard/useSignedInUserGuard";

import "@aws-amplify/ui-react/styles.css";
import "./SignInPage.css";
import { Box, Container, Flex } from "@chakra-ui/react";
import { Header } from "components/navigation/Header/Header";

export const SignInPage: FC = () => {
  useSignedInUserGuard("/");

  return (
    <Flex flexDirection="column" minHeight="100%">
      <Header showActionButton={false} />
      <Container maxW="container.md" mt={8} pb={24} flex={1}>
        <Box mt={4}>
          <Authenticator>{() => <div></div>}</Authenticator>
        </Box>
      </Container>
    </Flex>
  );
};
