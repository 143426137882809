import { FC, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  isDeleting: boolean;
};

export const AppDeleteAlertDialog: FC<Props> = ({
  onClose,
  onSubmit,
  isOpen,
  isDeleting,
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      closeOnEsc={!isDeleting}
      closeOnOverlayClick={!isDeleting}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={4}>
          <AlertDialogHeader>App を削除</AlertDialogHeader>
          <AlertDialogBody>削除すると元に戻せません。</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} disabled={isDeleting} ref={cancelRef}>
              キャンセル
            </Button>
            <Button
              colorScheme="red"
              onClick={onSubmit}
              isLoading={isDeleting}
              ml={4}
            >
              削除
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
