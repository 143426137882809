import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AppListPage,
  AppDetailPage,
  AppCreatePage,
  AppEditPage,
  SignInPage,
  NotFoundPage,
  ErrorBoundary,
} from "./pages";

import { AuthContextProvider } from "./context/AuthContext";
import { AppSearchContextProvider } from "./context/AppSearchContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";

Amplify.configure(awsconfig);
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
            <CSSReset />
            <BrowserRouter>
              <AppSearchContextProvider>
                <Routes>
                  <Route path="/" element={<AppListPage />} />
                  <Route path="/app/:appId" element={<AppDetailPage />}>
                    <Route path=":iconImageKey" element={<AppDetailPage />} />
                  </Route>
                  <Route path="/app/:appId/edit" element={<AppEditPage />} />
                  <Route path="/createApp" element={<AppCreatePage />} />
                  <Route path="/signIn" element={<SignInPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </AppSearchContextProvider>
            </BrowserRouter>
          </ChakraProvider>
        </QueryClientProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
