import { FC, ReactNode } from "react";
import { FlexProps, Box, Flex, Icon } from "@chakra-ui/react";
import { BiCheckCircle } from "react-icons/bi";

type Props = {
  bg?: FlexProps["bg"];
  children?: ReactNode;
};

export const Toast: FC<Props> = ({ bg = "teal.500", children }) => {
  return (
    <Flex
      color="white"
      p={3}
      bg={bg}
      fontWeight="bold"
      borderRadius="md"
      justifyContent="center"
      alignItems="center"
    >
      <Icon as={BiCheckCircle} />
      <Box ml={2}>{children}</Box>
    </Flex>
  );
};
