import { FC } from "react";
import {
  useToast,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded, BiEdit, BiTrash } from "react-icons/bi";
import { AppDeleteAlertDialog } from "../AppDeleteAlertDialog/AppDeleteAlertDialog";
import { useQueryClient } from "react-query";
import { useDeleteAppMutation } from "hooks/useDeleteAppMutation/useDeleteAppMutation";
import { Toast } from "components/feedback/Toast/Toast";

type Props = {
  appId: string;
};

export const AppActionMenu: FC<Props> = ({ appId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const deleteAppMutation = useDeleteAppMutation();

  const handleSelectEdit = () => {
    navigate(`/app/${appId}/edit`, { state: { hasPrevPath: true } });
  };

  const handleSelectDelete = () => {
    onOpen();
  };

  const handleSubmitDelete = () => {
    deleteAppMutation.mutate(
      { id: appId },
      {
        onSuccess: () => {
          queryClient.removeQueries(["appDetail", appId]);
          queryClient.removeQueries("appList");

          toast({
            render: () => <Toast>App を削除しました。</Toast>,
          });

          navigate("/");
        },
      }
    );
  };

  return (
    <>
      <Menu placement="bottom-end" closeOnBlur={!isOpen} closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          icon={<BiDotsVerticalRounded />}
          aria-label=""
          borderRadius="50%"
          background="gray.100"
        />
        <MenuList>
          <MenuItem onClick={handleSelectEdit} icon={<BiEdit />}>
            編集
          </MenuItem>
          <MenuItem onClick={handleSelectDelete} icon={<BiTrash />} color="red">
            削除
          </MenuItem>
        </MenuList>
      </Menu>
      <AppDeleteAlertDialog
        isOpen={isOpen}
        isDeleting={deleteAppMutation.isLoading}
        onClose={onClose}
        onSubmit={handleSubmitDelete}
      />
    </>
  );
};
