import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { AspectRatio, Center, Grid, Link } from "@chakra-ui/react";
import { S3Image } from "components/media/S3Image/S3Image";
import { Image } from "API";

interface Props {
  images: (Image | null)[];
}

export const AppVersionList: FC<Props> = ({ images }) => {
  return (
    <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={4} mt={4}>
      {images.map((image, index) => {
        return (
          image && (
            <Link
              key={index}
              as={ReactRouterLink}
              to={image.name}
              cursor="zoom-in"
              display="inline-block"
              _hover={{
                textDecoration: "none",
              }}
            >
              <AspectRatio ratio={1} w="100%">
                <S3Image imgKey={image.name} showThumbNail visibleByDefault />
              </AspectRatio>

              <Center mt={2}>{image.version}</Center>
            </Link>
          )
        );
      })}
    </Grid>
  );
};
