import { Component } from "react";
import { Container, Heading, Text, Flex } from "@chakra-ui/react";

export class ErrorBoundary extends Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: エラー通知
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex flexDirection="column" minHeight="100%">
          <Container
            maxW="container.md"
            marginTop={8}
            flex={1}
            textAlign="center"
          >
            <Heading as="h1">Something went wrong</Heading>
            <Text mt={8}>エラーが発生しました。</Text>
          </Container>
        </Flex>
      );
    }

    return this.props.children;
  }
}
