import { FC } from "react";
import {
  AspectRatio,
  Box,
  Grid,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";

export const SkeltonAppList: FC = () => {
  return (
    <Grid
      as="ul"
      gap={[4, 4, 8]}
      gridTemplateColumns={[
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
      ]}
    >
      {Array.from(Array(12)).map((...[, i]) => {
        return (
          <Box as="li" listStyleType="none" key={i}>
            <AspectRatio w="100%" ratio={1}>
              <Skeleton h="100%" startColor="gray.100" endColor="gray.400" />
            </AspectRatio>
            <Box mt={4}>
              <SkeletonText
                noOfLines={2}
                startColor="gray.100"
                endColor="gray.400"
              />
            </Box>
          </Box>
        );
      })}
    </Grid>
  );
};
