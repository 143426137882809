import { FC } from "react";
import { Platform } from "API";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { AddAppVersionButton } from "./AddAppVersionButton/AddAppVersionButton";
import { AppVersionList } from "./AppVersionList/AppVersionList";
import { AppFormData } from "types/types";
import { getPlatformDisplayValue } from "utils/utils";

type Props = {
  onSubmit: (data: AppFormData) => Promise<void>;
  isLoading: boolean;
  submitLabel?: string;
  defaultValues?: AppFormData;
};

export const AppForm: FC<Props> = ({
  onSubmit,
  defaultValues,
  isLoading,
  submitLabel = "作成",
}) => {
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<AppFormData>({
    defaultValues,
  });

  register("images", {
    required: "必須項目です。",
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "images",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing={8} mt={8}>
        <FormControl isInvalid={!!errors.name} isRequired>
          <FormLabel htmlFor="appName">App 名</FormLabel>
          <Input
            id="appName"
            placeholder="Finder や Slack など"
            {...register("name", {
              required: "必須項目です。",
            })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.images}>
          <FormLabel htmlFor="images">バージョン情報</FormLabel>

          <AppVersionList
            list={fields}
            // TODO useCallback
            onUpdate={({ index, name, version }) => {
              update(index, {
                name,
                version,
              });
            }}
            onDelete={(index) => {
              remove(index);
            }}
          />

          <FormErrorMessage>
            {errors.images && "必須項目です。"}
          </FormErrorMessage>

          <Center mt={4}>
            <AddAppVersionButton
              onSubmit={({ name, version }) => {
                clearErrors("images");
                append({ name, version });
              }}
            />
          </Center>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="platform">プラットフォーム</FormLabel>
          <Select id="platform" {...register("platform")}>
            {Object.entries(Platform).map(([key, value]) => (
              <option key={key} value={key}>
                {getPlatformDisplayValue(value)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="developer">Developer</FormLabel>
          <Input
            id="developer"
            placeholder="Apple や Google など"
            {...register("developer")}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="url">App の URL</FormLabel>
          <Input
            id="url"
            placeholder="https://example.com"
            type="url"
            {...register("url")}
          />
        </FormControl>
      </VStack>

      <Box mt={12}>
        <Button
          type="submit"
          size="lg"
          colorScheme="teal"
          width="fullch"
          isLoading={isLoading}
        >
          {submitLabel}
        </Button>
      </Box>
    </form>
  );
};
