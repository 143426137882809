import { FC, ReactElement } from "react";
import { Flex, Box, FlexProps } from "@chakra-ui/react";

type Props = {
  title: string;
  description: string | ReactElement;
} & FlexProps;

export const AppDescriptionItem: FC<Props> = ({
  title,
  description,
  ...rest
}) => {
  return (
    <Flex flexWrap="nowrap" w="100%" {...rest}>
      <Box fontWeight="bold" minWidth="150px">
        {title}
      </Box>
      <Box ml={4} wordBreak={"break-all"}>
        {description}
      </Box>
    </Flex>
  );
};
