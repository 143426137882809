import { FC } from "react";
import { BsTwitter, BsGithub } from "react-icons/bs";
import { Box, Container, Link, Flex } from "@chakra-ui/react";

export const Footer: FC = () => {
  return (
    <Box as="footer" background="gray.100" py="1.5rem">
      <Container maxW="container.xl">
        <Flex alignItems="center" gap="1rem" justifyContent="flex-end">
          <Box fontWeight="bold">Contact</Box>

          <Link
            href="https://twitter.com/kjugk1222"
            isExternal
            display="flex"
            alignItems="center"
            gap="0.3rem"
          >
            <BsTwitter />
            kjugk
          </Link>

          <Link
            href="https://github.com/mnkd"
            isExternal
            display="flex"
            alignItems="center"
            gap="0.3rem"
          >
            <BsGithub />
            mnkd
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};
