import { FC, useCallback, useEffect } from "react";
import { Box, useDisclosure, Portal } from "@chakra-ui/react";
import { AppSearchForm } from "../AppSearchForm/AppSearchForm";

export const FloatingAppSearchForm: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleScroll = useCallback(() => {
    const currentY = window.scrollY;
    if (currentY >= 180) {
      onOpen();
    } else {
      onClose();
    }
  }, [onOpen, onClose]);

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Portal>
      <Box
        display={isOpen ? "block" : "none"}
        position="fixed"
        zIndex={10}
        top={0}
        left={0}
        right={0}
        background="white"
        py="4"
        boxShadow="0px 2px 4px rgba(0,0,0,0.19)"
      >
        <AppSearchForm />
      </Box>
    </Portal>
  );
};
