import { API } from "aws-amplify";
import { useMutation } from "react-query";
import { deleteApp } from "graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { App, DeleteAppInput, DeleteAppMutation } from "API";
import { wait } from "utils/utils";

const deleteAppMutation = async (input: DeleteAppInput) => {
  const result = (await API.graphql({
    query: deleteApp,
    variables: {
      input: {
        id: input.id,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<DeleteAppMutation>;

  await wait(1000);

  return result.data?.deleteApp;
};

export const useDeleteAppMutation = () => {
  return useMutation<App | null | undefined, Error, DeleteAppInput, unknown>(
    (input) => deleteAppMutation(input)
  );
};
