import { FC } from "react";
import { Grid } from "@chakra-ui/react";
import { ImageInput } from "API";
import { AppVersionListItem } from "./AppVersionListItem/AppVersionListItem";

type Props = {
  list: (ImageInput & Record<"id", string>)[];
  onUpdate: (result: { index: number; name: string; version: string }) => void;
  onDelete: (index: number) => void;
};

export const AppVersionList: FC<Props> = ({ list, onUpdate, onDelete }) => {
  return (
    <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={4} mt={4}>
      {list.map((image, index) => {
        return (
          <AppVersionListItem
            key={image.id}
            item={image}
            onUpdate={({ name, version }) => {
              onUpdate({ index, name, version });
            }}
            onDelete={() => {
              onDelete(index);
            }}
          />
        );
      })}
    </Grid>
  );
};
