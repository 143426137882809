import { FC } from "react";
import { useParams } from "react-router-dom";
import { useUnsignedInUserGuard } from "hooks/useUnSignedInUserGuard/useUnsignedInUserGuard";
import { AppEditForm } from "./AppEditForm/AppEditForm";
import { useAppDetailQuery } from "hooks/useAppDtailQuery/useAppDetailQuery";
import { Header } from "components/navigation/Header/Header";
import { Box, Container, Flex, Heading } from "@chakra-ui/react";

export const AppEditPage: FC = () => {
  useUnsignedInUserGuard("/");

  const { appId = "" } = useParams<{ appId: string }>();
  const { data, isLoading } = useAppDetailQuery(appId);

  // TODO ページ内で Loading 出す
  if (isLoading) {
    return <div>Loading..</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <Flex flexDirection="column" minHeight="100%">
      <Header showActionButton={false} />
      <Container maxW="container.md" marginY={8} pb={24} flex={1}>
        <Heading as="h1">App を編集</Heading>
        <Box mt={4}>
          <AppEditForm originalApp={data} />
        </Box>
      </Container>
    </Flex>
  );
};
