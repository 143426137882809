import { Platform } from "API";

export const wait = (millisec: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, millisec);
  });
};

// 配列の最後に格納されている値を返す。
export const getLastItem = <T>(list: T[] | undefined | null): T | undefined => {
  if (!list) return undefined;

  const copied = [...list];
  return copied.reverse().shift();
};

// Platform の表示用表記を返す。
export const getPlatformDisplayValue = (key: Platform): string => {
  switch (key) {
    case Platform.IOS:
      return "iOS";
    case Platform.MACOS:
      return "macOS";
    case Platform.ANDROID:
      return "Android";
    default:
      return "";
  }
};
