import { FC } from "react";
import { Header } from "components/navigation/Header/Header";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const NotFoundPage: FC = () => {
  // TODO noindex meta tag を追加する。
  return (
    <Flex flexDirection="column" minHeight="100%">
      <Header showActionButton={false} />
      <Container maxW="container.md" marginTop={8} flex={1} textAlign="center">
        <Heading>Page Not Found</Heading>
        <Text mt={8}>お探しのぺーじはみつかりませんでした。</Text>
        <Box mt={8}>
          <Link to="/">トップに戻る</Link>
        </Box>
      </Container>
    </Flex>
  );
};
