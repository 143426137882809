import { FC } from "react";
import { useQueryClient } from "react-query";
import { App, UpdateAppInput } from "API";
import { useNavigate, useLocation } from "react-router-dom";
import { useUpdateAppMutation } from "hooks/useUpdateAppMutation/useUpdateAppMutation";
import { useToast } from "@chakra-ui/react";
import { AppForm } from "components/form/AppForm/AppForm";
import { AppFormData, LocationState } from "types/types";
import { Toast } from "components/feedback/Toast/Toast";

interface Props {
  originalApp: App;
}

export const AppEditForm: FC<Props> = ({ originalApp }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateAppMutation = useUpdateAppMutation();
  const toast = useToast();
  const location = useLocation();

  const onSubmit = async (data: AppFormData) => {
    const input: UpdateAppInput = {
      id: originalApp.id,
      name: data.name,
      searchName: data.name.toLowerCase(),
      platform: data.platform,
      images: data.images,
      url: data.url ? data.url : undefined,
      developer: data.developer,
    };

    updateAppMutation.mutate(input, {
      onSuccess: () => {
        toast({
          render: () => <Toast>App を更新しました。</Toast>,
        });
        queryClient.removeQueries(["appDetail", originalApp.id]);
        queryClient.removeQueries("appList");

        if ((location.state as LocationState)?.hasPrevPath) {
          navigate(-1);
        } else {
          navigate(`/app/${originalApp.id}`);
        }
      },
      // TODO: error 処理 or Feedback
    });
  };

  return (
    <AppForm
      onSubmit={onSubmit}
      isLoading={updateAppMutation.isLoading}
      submitLabel="更新"
      defaultValues={{
        name: originalApp.name,
        platform: originalApp.platform,
        images: originalApp.images || [],
        url: originalApp.url || "",
        developer: originalApp.developer || "",
      }}
    />
  );
};
