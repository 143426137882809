import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { App } from "API";
import { AspectRatio, Box, GridItem, Link } from "@chakra-ui/react";
import { S3Image } from "components/media/S3Image/S3Image";
import { getLastItem } from "utils/utils";

interface Props {
  app: App | null;
}

export const AppListItem: FC<Props> = ({ app }) => {
  if (app === null) {
    return null;
  }

  const imgKey = getLastItem(app.images)?.name;

  return (
    <GridItem as="li" listStyleType="none">
      <Link
        to={`app/${app.id}`}
        state={{
          hasPrevPath: true,
        }}
        as={ReactRouterLink}
        display="block"
        _hover={{
          textDecoration: "none",
        }}
      >
        <AspectRatio w="100%" ratio={1}>
          <S3Image imgKey={imgKey} showThumbNail />
        </AspectRatio>

        <Box mt={4} textAlign="center" fontSize="large" wordBreak="break-word">
          {app.name}
        </Box>
      </Link>
    </GridItem>
  );
};
