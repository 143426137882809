import { FC, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDetailQuery } from "hooks/useAppDtailQuery/useAppDetailQuery";
import { Header } from "components/navigation/Header/Header";
import { PageLoadSpinner } from "components/feedback/PageLoadSpinner/PageLoadSpinner";
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Link,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth/useAuth";
import { AppActionMenu } from "./AppActionMenu/AppActionMenu";
import { parseISO } from "date-fns";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { getPlatformDisplayValue } from "utils/utils";
import { AppDescriptionItem } from "./AppDescriptionItem/AppDescriptionItem";
import { AppVersionList } from "./AppVersionList/AppVersionList";
import { AppIconDetailModal } from "./AppIconDetailModal/AppIconDetailModal";
import { BiArrowBack } from "react-icons/bi";
import { LocationState } from "types/types";

export const AppDetailPage: FC = () => {
  const { appId = "" } = useParams<{ appId: string }>();
  const { data, isLoading } = useAppDetailQuery(appId);
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // 表示時にスクロール位置をリセットする。
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickBackLink = () => {
    if ((location.state as LocationState)?.hasPrevPath) {
      navigate(-1);
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      }, 0);
      navigate("/");
    }
  };

  if (!isLoading && data === null) {
    return <NotFoundPage />;
  }

  return (
    // TODO: レイアウトをコンポーネントに切り出す(ex. SingleColumnPageLayout)
    <Flex flexDirection="column" minHeight="100%">
      <Header />
      <Container maxW="container.md" marginTop={8} pb={24} flex={1}>
        {isLoading || !data ? (
          <PageLoadSpinner />
        ) : (
          // TODO: コンポーネントに切り出して, data, isSignedIn 依存でメモ化する
          <Box>
            <Flex justifyContent="space-between" alignItems="flex-start">
              <Heading as="h1">{data.name}</Heading>
              {isSignedIn && <AppActionMenu appId={data.id} />}
            </Flex>

            <Box mt={8}>
              <Text fontWeight="bold">バージョン情報</Text>
              <AppVersionList images={data.images || []} />
            </Box>

            <AppIconDetailModal
              imageKeys={(data.images || []).map((image) => image?.name || "")}
            />

            <VStack spacing={4} mt={8}>
              <AppDescriptionItem
                title="プラットフォーム"
                description={getPlatformDisplayValue(data.platform)}
              />

              {data.developer && (
                <AppDescriptionItem
                  title="Developer"
                  description={data.developer}
                />
              )}

              {data.url && (
                <AppDescriptionItem
                  title="App の URL"
                  description={
                    <Link
                      href={data.url}
                      color="teal.500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.url}
                    </Link>
                  }
                />
              )}

              <AppDescriptionItem
                title="最終更新日"
                description={parseISO(data.updatedAt).toLocaleString()}
              />
            </VStack>

            <Box mt={8}>
              <Button
                onClick={onClickBackLink}
                variant="link"
                leftIcon={<BiArrowBack />}
              >
                Back
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </Flex>
  );
};
