import { API } from "aws-amplify";
import { useQuery } from "react-query";
import { getApp } from "graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetAppQuery } from "API";

const getAppDetail = async (appId: string) => {
  const result = (await API.graphql({
    query: getApp,
    variables: {
      id: appId,
    },
  })) as GraphQLResult<GetAppQuery>;

  return result.data?.getApp;
};

export const useAppDetailQuery = (appId: string) => {
  return useQuery(
    [`appDetail`, appId], // id を混ぜて、ユニークな key を生成している。
    () => {
      return getAppDetail(appId);
    },
    {
      staleTime: 600000,
    }
  );
};
