/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAppInput = {
  id?: string | null,
  name: string,
  searchName?: string | null,
  platform: Platform,
  images?: Array< ImageInput | null > | null,
  url?: string | null,
  developer?: string | null,
  type?: string | null,
  createdAt?: string | null,
};

export enum Platform {
  MACOS = "MACOS",
  IOS = "IOS",
  ANDROID = "ANDROID",
}


export type ImageInput = {
  name: string,
  version: string,
};

export type ModelAppConditionInput = {
  name?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  url?: ModelStringInput | null,
  developer?: ModelStringInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAppConditionInput | null > | null,
  or?: Array< ModelAppConditionInput | null > | null,
  not?: ModelAppConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPlatformInput = {
  eq?: Platform | null,
  ne?: Platform | null,
};

export type App = {
  __typename: "App",
  id: string,
  name: string,
  searchName?: string | null,
  platform: Platform,
  images?:  Array<Image | null > | null,
  url?: string | null,
  developer?: string | null,
  type?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Image = {
  __typename: "Image",
  name: string,
  version: string,
};

export type UpdateAppInput = {
  id: string,
  name?: string | null,
  searchName?: string | null,
  platform?: Platform | null,
  images?: Array< ImageInput | null > | null,
  url?: string | null,
  developer?: string | null,
  type?: string | null,
  createdAt?: string | null,
};

export type DeleteAppInput = {
  id: string,
};

export type ModelAppFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  searchName?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  url?: ModelStringInput | null,
  developer?: ModelStringInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAppFilterInput | null > | null,
  or?: Array< ModelAppFilterInput | null > | null,
  not?: ModelAppFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAppConnection = {
  __typename: "ModelAppConnection",
  items:  Array<App | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateAppMutationVariables = {
  input: CreateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type CreateAppMutation = {
  createApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppMutationVariables = {
  input: UpdateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type UpdateAppMutation = {
  updateApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppMutationVariables = {
  input: DeleteAppInput,
  condition?: ModelAppConditionInput | null,
};

export type DeleteAppMutation = {
  deleteApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UploadIconImageMutationVariables = {
  imageData: string,
};

export type UploadIconImageMutation = {
  uploadIconImage: string,
};

export type GetAppQueryVariables = {
  id: string,
};

export type GetAppQuery = {
  getApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppsQueryVariables = {
  filter?: ModelAppFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppsQuery = {
  listApps?:  {
    __typename: "ModelAppConnection",
    items:  Array< {
      __typename: "App",
      id: string,
      name: string,
      searchName?: string | null,
      platform: Platform,
      images?:  Array< {
        __typename: "Image",
        name: string,
        version: string,
      } | null > | null,
      url?: string | null,
      developer?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AppsByDateQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AppsByDateQuery = {
  appsByDate?:  {
    __typename: "ModelAppConnection",
    items:  Array< {
      __typename: "App",
      id: string,
      name: string,
      searchName?: string | null,
      platform: Platform,
      images?:  Array< {
        __typename: "Image",
        name: string,
        version: string,
      } | null > | null,
      url?: string | null,
      developer?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAppSubscription = {
  onCreateApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppSubscription = {
  onUpdateApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppSubscription = {
  onDeleteApp?:  {
    __typename: "App",
    id: string,
    name: string,
    searchName?: string | null,
    platform: Platform,
    images?:  Array< {
      __typename: "Image",
      name: string,
      version: string,
    } | null > | null,
    url?: string | null,
    developer?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
