import { FC } from "react";
import { AppCreateForm } from "./AppCreateForm/AppCreateForm";
import { useUnsignedInUserGuard } from "hooks/useUnSignedInUserGuard/useUnsignedInUserGuard";
import { Header } from "components/navigation/Header/Header";
import { Flex, Box, Container, Heading } from "@chakra-ui/react";

export const AppCreatePage: FC = () => {
  useUnsignedInUserGuard("/");

  return (
    <Flex flexDirection="column" minHeight="100%">
      <Header showActionButton={false} />
      <Container maxW="container.md" mt={8} pb={24} flex={1}>
        <Heading as="h1">App を作成</Heading>
        <Box mt={4}>
          <AppCreateForm />
        </Box>
      </Container>
    </Flex>
  );
};
