import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === null) throw Error("Auth context is null.");

  return context;
};
