import { FC } from "react";
import { CreateAppInput } from "API";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCreateAppMutation } from "hooks/useCreateAppMutation/useCreateAppMutation";
import { useToast } from "@chakra-ui/react";
import { AppForm } from "components/form/AppForm/AppForm";
import { AppFormData } from "types/types";
import { Toast } from "components/feedback/Toast/Toast";

export const AppCreateForm: FC = () => {
  const queryClient = useQueryClient();
  const createAppMutation = useCreateAppMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: AppFormData) => {
    const input: CreateAppInput = {
      name: data.name,
      searchName: data.name.toLocaleLowerCase(),
      platform: data.platform,
      url: data.url ? data.url : undefined, // AWSURL 型は空文字を許容しないので、空文字は undefined に変換する。
      developer: data.developer,
      images: data.images,
    };

    createAppMutation.mutate(input, {
      onSuccess: async (app) => {
        toast({
          render: () => <Toast>Appを作成しました。</Toast>,
        });
        queryClient.removeQueries("appList");

        navigate(`/app/${app?.id}`);
      },
      onError: (e) => {
        console.error(e.message);
      },
    });
  };

  return (
    <AppForm onSubmit={onSubmit} isLoading={createAppMutation.isLoading} />
  );
};
