import { FC, useCallback, useEffect } from "react";
import { Box, Flex, Portal, IconButton, Fade } from "@chakra-ui/react";
import { BiChevronRight, BiChevronLeft, BiX } from "react-icons/bi";
import { S3Image } from "components/media/S3Image/S3Image";
import { useParams, useNavigate } from "react-router-dom";

type Props = {
  imageKeys: string[];
};

export const AppIconDetailModal: FC<Props> = ({ imageKeys }) => {
  const { iconImageKey = "" } = useParams<{ iconImageKey: string }>();
  const isOpen = iconImageKey !== "";
  const navigate = useNavigate();
  const hasMultipleImage = imageKeys.length >= 2;

  const close = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const loadPrevImage = useCallback(() => {
    const currentIndex = imageKeys.indexOf(iconImageKey);
    const nextIndex = (currentIndex - 1 + imageKeys.length) % imageKeys.length;

    navigate(imageKeys[nextIndex], { replace: true });
  }, [imageKeys, iconImageKey, navigate]);

  const loadNextImage = useCallback(() => {
    const currentIndex = imageKeys.indexOf(iconImageKey);
    const nextIndex = (currentIndex + 1 + imageKeys.length) % imageKeys.length;

    navigate(imageKeys[nextIndex], { replace: true });
  }, [imageKeys, iconImageKey, navigate]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "ArrowRight") {
        loadNextImage();
      } else if (event.code === "ArrowLeft") {
        loadPrevImage();
      } else if (event.code === "Escape") {
        close();
      }
    },
    [loadNextImage, loadPrevImage, close]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isOpen, handleKeyPress]);

  // ios の 100vh 問題に対処するため、あえて Modal を使っていない。
  // TODO: focus trap
  return (
    <Portal>
      <Fade in={isOpen} unmountOnExit>
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          background="black"
        >
          <IconButton
            variant="link"
            icon={<BiX />}
            aria-label="close"
            fontSize={36}
            position="absolute"
            top={4}
            right={4}
            zIndex={2}
            onClick={close}
          />

          <Flex
            position="absolute"
            width="100%"
            height="100%"
            background="black"
            zIndex={1}
            padding={4}
          >
            {hasMultipleImage && (
              <Flex alignItems="center">
                <IconButton
                  variant="link"
                  fontSize={[36, 48]}
                  icon={<BiChevronLeft />}
                  aria-label="show prev image"
                  onClick={loadPrevImage}
                />
              </Flex>
            )}

            <Box
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <S3Image
                imgKey={iconImageKey}
                visibleByDefault
                showPlaceholder={false}
              />
            </Box>

            {hasMultipleImage && (
              <Flex alignItems="center">
                <IconButton
                  variant="link"
                  fontSize={[36, 48]}
                  icon={<BiChevronRight />}
                  aria-label="show next image"
                  onClick={loadNextImage}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Fade>
    </Portal>
  );
};
