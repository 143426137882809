import {
  createContext,
  FC,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth, Hub } from "aws-amplify";

/**
 * ユーザーのログイン状態を管理する Context.
 */

interface Context {
  currentUser?: CognitoUserInterface;
  isSignedIn: boolean;
}

type Props = {
  children?: ReactNode;
};

export const AuthContext = createContext<Context | null>(null);

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [currentUser, setUser] = useState<CognitoUserInterface | undefined>();
  const isSignedIn = !!currentUser;

  const updateCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (e) {
      setUser(undefined);
    }
  };

  const initializeUser = useCallback(async () => {
    await updateCurrentUser();
    setInitialized(true);
  }, []);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  // 認証状態の変更に応じて、state を更新する
  useEffect(() => {
    const listener = (data: any) => {
      updateCurrentUser();
    };
    Hub.listen("auth", listener);

    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isSignedIn,
      }}
    >
      {initialized && children}
    </AuthContext.Provider>
  );
};
