/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      name
      searchName
      platform
      images {
        name
        version
      }
      url
      developer
      type
      createdAt
      updatedAt
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchName
        platform
        images {
          name
          version
        }
        url
        developer
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appsByDate = /* GraphQL */ `
  query AppsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        platform
        images {
          name
          version
        }
        url
        developer
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
