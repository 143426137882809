import { useEffect } from "react";
import { useAuth } from "hooks/useAuth/useAuth";
import { useNavigate } from "react-router-dom";

export const useUnsignedInUserGuard = (redirectPath: string) => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSignedIn) {
      navigate(redirectPath);
    }
  }, [redirectPath, navigate, isSignedIn]);
};
