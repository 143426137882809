import { FC } from "react";
import { AppListItem } from "../AppListItem/AppListItem";
import { Box, Grid } from "@chakra-ui/react";
import { App } from "API";

type Props = {
  items: (App | null)[];
};

export const AppList: FC<Props> = ({ items }) => {
  if (items.length <= 0) {
    return (
      <Box textAlign="center" fontWeight="bold" fontSize="2xl">
        No Result Found
      </Box>
    );
  }

  return (
    <Grid
      as="ul"
      gap={[4, 4, 8]}
      gridTemplateColumns={[
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
      ]}
    >
      {items.map((app, index) => {
        return <AppListItem key={index} app={app} />;
      })}
    </Grid>
  );
};
