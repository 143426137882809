import {
  createContext,
  FC,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useAppListQuery } from "hooks/useAppListQuery/useAppListQuery";
import { AppsByDateQuery } from "API";

/**
 *  App リストの状態を管理する Context
 */

interface Context {
  temporaryNameQuery: string;
  setTemporaryNameQuery: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  appList: AppsByDateQuery["appsByDate"];
  updateAppList: () => void;
  resetAppList: () => void;
}

type Props = {
  children?: ReactNode;
};

export const AppSearchContext = createContext<Context | null>(null);

export const AppSearchContextProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q") || "";
  const { data, isLoading } = useAppListQuery(query);
  const [temporaryNameQuery, setTemporaryNameQuery] = useState("");

  useEffect(() => {
    setTemporaryNameQuery(query);
  }, [location, query]);

  const updateAppList = () => {
    navigate({ pathname: "/", search: `q=${temporaryNameQuery}` });
  };

  const resetAppList = () => {
    setTemporaryNameQuery("");
    navigate({ pathname: "/" });
  };

  return (
    <AppSearchContext.Provider
      value={{
        temporaryNameQuery,
        setTemporaryNameQuery,
        isLoading,
        appList: data,
        updateAppList,
        resetAppList,
      }}
    >
      {children}
    </AppSearchContext.Provider>
  );
};

export const useAppSearchContext = () => {
  const context = useContext(AppSearchContext);

  if (context === null) throw Error("AppSearch context is null.");

  return context;
};
