import { FC } from "react";
import { Center, Spinner } from "@chakra-ui/react";

export const PageLoadSpinner: FC = () => {
  return (
    <Center py={12}>
      <Spinner size="xl" thickness="4px" color="gray.400" />
    </Center>
  );
};
