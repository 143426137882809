import { FC, useEffect, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { AppImageUploader } from "./AppImageUploader/AppImageUploader";
import { useForm } from "react-hook-form";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (result: { name: string; version: string }) => void;
  defaultValues?: FormData;
  dialogTitle?: string;
  submitLabel?: string;
};

type FormData = {
  name: string;
  version: string;
};

export const AppVersionEditDialog: FC<Props> = ({
  onSubmit,
  onClose,
  isOpen,
  defaultValues,
  dialogTitle = "バージョン情報を追加",
  submitLabel = "追加",
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  register("name", {
    required: "必須項目です",
  });

  const handleImageChange = (key: string | null) => {
    setValue("name", key || "");
  };

  const onSumitForm = (data: FormData) => {
    onSubmit({ name: data.name, version: data.version });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={4}>
          <AlertDialogHeader>{dialogTitle}</AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel htmlFor="image">アイコン</FormLabel>
              <AppImageUploader
                onChange={handleImageChange}
                defaultImageKey={getValues("name")}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.version} isRequired mt={4}>
              <FormLabel htmlFor="imageVersion">バージョン情報</FormLabel>
              <Input
                id="version"
                placeholder="1.0.0 や Big Sur など"
                {...register("version", { required: "必須項目です。" })}
              />
              <FormErrorMessage>
                {errors.version && errors.version.message}
              </FormErrorMessage>
            </FormControl>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button type="button" onClick={onClose} ref={cancelRef}>
              キャンセル
            </Button>
            <Button
              colorScheme="teal"
              type="submit"
              ml={4}
              onClick={handleSubmit(onSumitForm)}
            >
              {submitLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
