import { FC, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
  imgKey?: string;
  visibleByDefault?: boolean;
  showThumbNail?: boolean;
  showPlaceholder?: boolean;
};

/**
 * S3 からダウンロードした画像を表示する。
 * Props でサイズは指定させずに、
 * 呼び出し側で Box の Aspect Ratio を設定することで、表示サイズを変更する想定。
 */
export const S3Image: FC<Props> = ({
  imgKey,
  visibleByDefault = false,
  showThumbNail = false,
  showPlaceholder = true,
}) => {
  const placeholderSrc = showPlaceholder
    ? "/images/placeholder.png"
    : undefined;

  const [signedUrl, setSignedUrl] = useState<string | undefined>(
    placeholderSrc
  );

  const initSignedUrl = async (key: string) => {
    const signedUrl = await Storage.get(key);
    setSignedUrl(signedUrl);
  };

  useEffect(() => {
    if (!imgKey) return;

    if (showThumbNail) {
      const [basename, ...ext] = imgKey.split(".");
      initSignedUrl(`${basename}_thumbnail.${ext.join()}`);
    } else {
      initSignedUrl(imgKey);
    }
  }, [imgKey, showThumbNail]);

  return (
    <LazyLoadImage
      src={signedUrl}
      alt=""
      visibleByDefault={visibleByDefault}
      style={{
        objectFit: "cover",
        maxWidth: "100%",
        maxHeight: "100%",
      }}
      placeholderSrc={placeholderSrc}
    />
  );
};
