import { FC } from "react";
import { AppList } from "./AppList/AppList";
import { AppSearchForm } from "./AppSearchForm/AppSearchForm";
import { Header } from "components/navigation/Header/Header";
import { Footer } from "components/navigation/Footer/Footer";
import { Box, Container, Flex } from "@chakra-ui/react";
import { SkeltonAppList } from "./SkeltonAppList/SkeltonAppList";
import { useAppSearchContext } from "context/AppSearchContext";
import { FloatingAppSearchForm } from "./FloatingAppSearchForm/FloatingAppSearchForm";

export const AppListPage: FC = () => {
  const { appList, isLoading } = useAppSearchContext();

  return (
    <>
      <Flex flexDirection="column" minHeight="100vh">
        <Header />
        <Box mt="6">
          <AppSearchForm />
        </Box>
        <Container maxW="container.lg" marginTop={10} flex={1} pb="2rem">
          {isLoading ? (
            <SkeltonAppList />
          ) : (
            <AppList items={appList?.items || []} />
          )}
        </Container>
        <Footer />
      </Flex>
      <FloatingAppSearchForm />
    </>
  );
};
