import { FC } from "react";
import { useAuth } from "hooks/useAuth/useAuth";
import { Link as ReactRouterLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppSearchContext } from "context/AppSearchContext";

import {
  Box,
  Button,
  Container,
  HStack,
  Link,
  Text,
  Flex,
} from "@chakra-ui/react";

type Props = {
  showActionButton?: boolean;
};

export const Header: FC<Props> = ({ showActionButton = true }) => {
  const { isSignedIn } = useAuth();
  const { resetAppList } = useAppSearchContext();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      // TODO: メッセージ送信
      alert("error");
      console.error(e);
    }
  };

  return (
    <Box as="header" background="gray.100">
      <Container maxW="container.xl">
        <Flex
          height="4.5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            as={ReactRouterLink}
            to="/"
            onClick={() => resetAppList()}
            _hover={{ textDecoration: "none" }}
          >
            <Text as="span" fontWeight="bold" fontSize={["md", "lg"]}>
              App Icon Catalog
            </Text>
          </Link>

          {showActionButton && isSignedIn && (
            <HStack spacing={4}>
              <Link as="button" onClick={handleSignOut}>
                サインアウト
              </Link>
              <ReactRouterLink to="/createApp" tabIndex={-1}>
                <Button colorScheme="teal">App を作成</Button>
              </ReactRouterLink>
            </HStack>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
