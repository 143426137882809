import { FC, useRef, ChangeEvent, FormEvent } from "react";
import {
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { BiSearch, BiX } from "react-icons/bi";
import { useAppSearchContext } from "context/AppSearchContext";

export const AppSearchForm: FC = () => {
  const {
    temporaryNameQuery,
    setTemporaryNameQuery,
    resetAppList,
    updateAppList,
  } = useAppSearchContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const changeQueryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTemporaryNameQuery(e.target.value);
  };

  const submitFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateAppList();
    inputRef.current?.blur();
    window.scrollTo(0, 0);
  };

  return (
    <Container maxW="container.sm">
      <form autoComplete="off" onSubmit={submitFormHandler}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<BiSearch />} />
          <Input
            ref={inputRef}
            type="text"
            pr="3rem"
            placeholder="App name で検索 (例: FaceTime)"
            value={temporaryNameQuery}
            onChange={changeQueryHandler}
          />
          {temporaryNameQuery !== "" && (
            <InputRightElement w="3rem">
              <IconButton
                onClick={() => {
                  resetAppList();
                  window.scrollTo(0, 0);
                }}
                aria-label="clear query"
                icon={<BiX />}
                isRound
                size="xs"
              />
            </InputRightElement>
          )}
        </InputGroup>
      </form>
    </Container>
  );
};
