import { FC } from "react";
import {
  AspectRatio,
  Box,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ImageInput } from "API";
import { S3Image } from "components/media/S3Image/S3Image";
import { BiDotsVerticalRounded, BiEdit, BiTrash } from "react-icons/bi";
import { AppVersionEditDialog } from "../../AppVersionEditDialog/AppVersionEditDialog";

type Props = {
  item: ImageInput & Record<"id", string>;
  onUpdate: (result: { name: string; version: string }) => void;
  onDelete: () => void;
};

export const AppVersionListItem: FC<Props> = ({ item, onUpdate, onDelete }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Box position="relative">
        <AspectRatio ratio={1} w="100%">
          <S3Image imgKey={item.name} showThumbNail />
        </AspectRatio>
        <Center mt={2}>{item.version}</Center>

        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            icon={<BiDotsVerticalRounded />}
            aria-label=""
            position="absolute"
            top={2}
            right={2}
            background="gray.100"
            borderRadius="50%"
          />
          <MenuList minW="0" width="8rem">
            <MenuItem icon={<BiEdit />} onClick={onOpen}>
              編集
            </MenuItem>
            <MenuItem icon={<BiTrash />} onClick={onDelete} color="red">
              削除
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <AppVersionEditDialog
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onUpdate}
        defaultValues={{
          name: item.name,
          version: item.version,
        }}
        dialogTitle="バージョン情報を編集"
        submitLabel="更新"
      />
    </>
  );
};
