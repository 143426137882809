import { useMutation } from "react-query";

import { API } from "aws-amplify";
import { uploadIconImage } from "graphql/mutations";
import { UploadIconImageMutation } from "API";
import { GraphQLResult } from "@aws-amplify/api-graphql";

const uploadImageToS3 = async (file: File) => {
  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject("画像をアップロードできませんでした。");
    });

  const base64ImageData = await toBase64(file);

  const result = (await API.graphql({
    query: uploadIconImage,
    variables: {
      imageData: base64ImageData,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<UploadIconImageMutation>;

  return result.data?.uploadIconImage || "";
};

export const useImageUploadMutation = () => {
  return useMutation<string, Error, File>((file) => uploadImageToS3(file));
};
