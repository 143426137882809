import { useQuery } from "react-query";
import { API } from "aws-amplify";
import { appsByDate } from "graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { AppsByDateQuery, ModelAppFilterInput } from "API";
import { wait } from "utils/utils";

const getAppList = async (nameFilter: string) => {
  const filter: ModelAppFilterInput | null =
    nameFilter === ""
      ? null
      : {
          searchName: {
            contains: nameFilter.toLowerCase(),
          },
        };

  const result = (await API.graphql({
    query: appsByDate,
    variables: {
      type: "App",
      sortDirection: "DESC",
      limit: 200,
      filter,
    },
  })) as GraphQLResult<AppsByDateQuery>;

  // ただ Skelton を見せたいがためだけの wait.
  await wait(500);

  return result.data?.appsByDate;
};

export const useAppListQuery = (nameFilter: string) => {
  return useQuery(["appList", nameFilter], () => getAppList(nameFilter), {
    staleTime: 600000,
  });
};
