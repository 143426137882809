import { ChangeEvent, FC, useState, useRef } from "react";
import { Box, Button, Image, HStack, AspectRatio } from "@chakra-ui/react";
import { S3Image } from "components/media/S3Image/S3Image";
import { useImageUploadMutation } from "hooks/useImageUploadMutation/useImageUploadMutation";
import { BiImageAlt } from "react-icons/bi";

type Props = {
  onChange: (key: string | null) => void;
  defaultImageKey?: string;
};

export const AppImageUploader: FC<Props> = ({
  onChange,
  defaultImageKey = "",
}) => {
  const [tmpImageName, setTmpImageName] = useState<string>(defaultImageKey);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageUploadMutation = useImageUploadMutation();

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    // 選択をキャンセルされた場合は何もしない。
    if (!event.target.files || event.target.files.length <= 0) {
      return;
    }

    const file = event.target.files[0];

    // TODO クライアントサイドでできるサイズバリデーションかける。
    imageUploadMutation.mutate(file, {
      onSuccess: (key) => {
        setTmpImageName(key);
        onChange(key);
      },
      onError: () => {
        setTmpImageName("");
        // TODO 失敗メッセージを送信する。
      },
    });
  };

  return (
    <Box>
      <HStack spacing={8}>
        <Box boxSize={["150px", "200px"]}>
          {tmpImageName ? (
            <AspectRatio ratio={1} w="100%">
              <S3Image imgKey={tmpImageName} showThumbNail />
            </AspectRatio>
          ) : (
            <Image src="/images/no_image.png" alt="no image" />
          )}
        </Box>

        <Button
          type="button"
          size="sm"
          isLoading={imageUploadMutation.isLoading}
          leftIcon={<BiImageAlt />}
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          画像を選択
        </Button>
      </HStack>

      <input
        id="image"
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={handleChangeFile}
        ref={fileInputRef}
      />
    </Box>
  );
};
