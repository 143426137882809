import { useMutation } from "react-query";
import { API } from "aws-amplify";
import { updateApp } from "graphql/mutations";
import { App, UpdateAppInput, UpdateAppMutation } from "API";
import { GraphQLResult } from "@aws-amplify/api-graphql";

const updateAppMutation = async (input: UpdateAppInput) => {
  const result = (await API.graphql({
    query: updateApp,
    variables: {
      input,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<UpdateAppMutation>;

  return result.data?.updateApp;
};

export const useUpdateAppMutation = () => {
  return useMutation<App | null | undefined, Error, UpdateAppInput, unknown>(
    (input) => updateAppMutation(input)
  );
};
