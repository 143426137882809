import { FC } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { AppVersionEditDialog } from "../AppVersionEditDialog/AppVersionEditDialog";

type Props = {
  onSubmit: (result: { name: string; version: string }) => void;
};

type FormData = {
  name: string;
  version: string;
};

export const AddAppVersionButton: FC<Props> = ({ onSubmit }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmitForm = (data: FormData) => {
    onSubmit({ name: data.name, version: data.version });
    onClose();
  };

  return (
    <>
      <Button type="button" size="sm" onClick={onOpen} leftIcon={<BiPlus />}>
        バージョン情報を追加
      </Button>
      <AppVersionEditDialog
        onSubmit={onSubmitForm}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
